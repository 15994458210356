export default {
    chart_darkgrey: '#979797',
    chart_grid_color: '#322f3d',
    chart_green: '#00ce99',
    chart_green_t: 'rgba(0, 206, 153, 0.4)',
    chart_green_speedometer: 'rgba(0, 206, 153, 0.55)',
    chart_blue: '#0091f2',
    chart_blue_t: 'rgba(0, 145, 242, 0.25)',
    chart_blue_speedometer: 'rgba(0, 145, 242, 0.4)',
    chart_lightgrey: '#cacaca',
    chart_lightgrey_t: 'rgba(202, 202, 202, 0.3)',
    chart_lightgrey_speedometer: 'rgba(202, 202, 202, 0.45)',
    chart_lightgrey_speedometer_needle: 'rgba(202, 202, 202, 0.80)',
    chart_purple_1: 'rgb(239, 206, 243)',
    chart_purple_1_t: 'rgb(239, 206, 243, 0.4)',
    chart_purple_2: 'rgb(221, 154, 229)',
    chart_purple_2_t: 'rgba(221, 154, 229, 0.4)',
    chart_purple_2_m: 'rgba(221, 154, 229, 0.7)',
    chart_purple_3: 'rgb(170, 46, 184)',
    chart_purple_3_t: 'rgba(170, 46, 184, 0.4)',
    chart_font_size: 11,
    chart_legend_font_size: 14,
    chart_legend_box_size: 8,
    chart_y_max: 20,
}
